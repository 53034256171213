<template>
  <div id="quotation-list">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-row>
              <b-col xl="6" sm="12">
                  <b-form-group label="Proyectos" label-for="projects">
                    <v-select
                      v-model="filtersForm.project"
                      label="name"
                      placeholder="Selecciona un Proyecto"
                      :options="projects"
                      :reduce="projects => projects.id">
                      <span slot="no-options">
                        No se encontraron opciones
                      </span>
                    </v-select>
                  </b-form-group>
                </b-col>
              <b-col xl="6" sm="12">
                  <b-form-group label="Estado" label-for="status">
                    <v-select
                      v-model="filtersForm.status"
                      label="name"
                      placeholder="Selecciona un Estado"
                      :options="statusList"
                      :reduce="statusList => statusList.id">
                      <span slot="no-options">
                        No se encontraron opciones
                      </span>
                    </v-select>
                  </b-form-group>
                </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-overlay
              :show="overlayList"
              opacity="1.0"
              spinner-small
              spinner-variant="primary"
              @hidden="_onHidden()">
              <div class="d-flex align-items-end justify-content-end">
                <div>
                  <b-overlay
                    :show="overlayForm"
                    opacity="0.5"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                    @hidden="_onHidden()">
                    <b-button class="mr-1"
                      ref="button"
                      variant="primary"
                      :disabled="overlayForm"
                      @click.prevent="_exportPreapprovals()" v-if="permissions_visible.download_preapprovals">
                      <feather-icon
                        icon="DownloadIcon"
                        class="mr-50"/>
                      <span class="align-middle">
                        Descargar Preaprobados
                      </span>
                    </b-button>
 
                  </b-overlay>
                </div>
              </div>
              <div class="mt-2">
                <b-table
                  hover
                  striped
                  show-empty
                  responsive="xl"
                  class="position-relative mb-1"
                  :items="preapprovals.data"
                  :fields="tableColumns"
                  empty-text="Datos no Disponibles">
                  <template #cell(created_at)="data">
                    {{ data.item.created_at | moment("DD-MM-YYYY") }}
                  </template>
                  <template #cell(status)="data">
                    <b-badge pill :variant="_colorBadge(data.item.status)">
                      {{ _nameBadge(data.item.status) }}
                    </b-badge>
                  </template>

                  
                  <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret
                      :right="$store.state.appConfig.isRTL">
                      <template #button-content>
                        <feather-icon size="16"
                          icon="MoreVerticalIcon"
                          class="align-middle text-body"/>
                      </template>

                      <b-dropdown-item @click.prevent="_editPreapproval(data.item)" v-if="permissions_visible.edit_preapprovals">
                        <feather-icon icon="EditIcon"/>
                        <span class="align-middle ml-50">Editar</span>
                    </b-dropdown-item> 
                     
                    <b-dropdown-item @click.prevent="_downloadDocumentation(data.item)" v-if="data.item.document_url != null && data.item.document_url != ''">
                        <feather-icon icon="EditIcon"/> 
                          <span class="align-middle ml-50" >Descargar</span> 
                    </b-dropdown-item>

                  </b-dropdown>
                </template>
              </b-table>
                <b-pagination
                  align="right"
                  v-model="filtersForm.page"
                  hide-goto-end-buttons
                  :total-rows="preapprovals.total"/>
              </div>
            </b-overlay>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex'
  import ToastificationContent from "@core/components/toastification/ToastificationContent";

  export default {
    name: 'PreapprovalsList',
    data () {
      return {
      permissions_visible:{
        edit_preapprovals:false,
        show_preapprovals:false,
        download_preapprovals:false,
      },
        overlayForm: false,
        overlayList: true,
        currentPage: 1,
        filtersForm: {
          project: '',
          status: '',
          page: 1
        },
        statusList: [
          {id:'approved',name:'Aprobado'},
          {id:'pending',name:'Pendiente'},
        ],
        tableColumns: [
          {key: 'id', sortable: false, label: 'ID'},
          {key: 'customer.name', sortable: true, label: 'Nombre'},
          {key: 'customer.last_name', sortable: true, label: 'Apellido'},
          {key: 'customer.phone', sortable: true, label: 'Télefono'},
          {key: 'project.name', sortable: true, label: 'Proyecto'},
          {key: 'project.commune.name', sortable: true, label: 'Comuna'},
          {key: 'status', sortable: true, label: 'Estado'},
          {key: 'created_at', sortable: true, label: 'Fecha'},
          {key: 'actions', sortable: false, label: 'Acciones'},
        ]
      }
    },
    mounted(){
      this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible);
    },
    beforeMount() {
      // this.filtersForm.page = this.currentPage
      this._fetchPreapprovals(this.filtersForm)
    },
    watch: {
      filtersForm: {
        handler(filter){
          this._fetchPreapprovals(filter)
        },
        deep: true
      },
      currentPage (val) {
        this.filtersForm.page = this.currentPage
        this._fetchPreapprovals(this.filtersForm)
      }
    },
    computed: {
      ...mapState('preapprovals',['preapprovals']),
      ...mapGetters('profile',['projects']),
    },
    methods: {
      ...mapActions('preapprovals',['exportPreapprovals','fetchPreapprovals']),
      async _fetchPreapprovals(filter) {
        this.overlayList = true
        await this.fetchPreapprovals(filter)
        .then((res) => {
        })  
        .finally(() => {
          this.overlayList = false
        })
      },
      async _downloadDocumentation(item) {
        
        if(item.document_url != null && item.document_url != ''){
          var anchor = document.createElement('a');
          anchor.href = item.document_url;
          anchor.target = '_blank';
          anchor.download = "descarga.pdf";
          anchor.click();
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No existe el archivo',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      },
      async _exportPreapprovals() {
        this.overlayForm = true
        await this.exportPreapprovals(this.filtersForm)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se ha enviado un correo con el archivo',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al exportar el archivo',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.overlayForm = false
        })
      },
      _editPreapproval(preapproval) {
        this.$router.push({ name: 'preapprovals-details', params: { id: preapproval.id, preapproval: preapproval } })
      },
      _onHidden() {
        this.$refs.button.focus()
      },
      _colorBadge (data) {
        switch (data) {
          case 'pending':
            return 'light-warning'
          case 'approved':
            return 'light-success'
          case 'rejected':
            return 'light-danger'
        }
      },
      _nameBadge (data) {
        switch (data) {
          case 'approved':
            return 'Aprobado'
          case 'pending':
            return 'Pendiente'
          case 'rejected':
            return 'Rechazado'
        }
      }
    },
  }
</script>
