<template>
  <div id="preapprovals">
    <preapprovals-list></preapprovals-list>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import PreapprovalsList from './components/PreapprovalsList'

  export default{
    name: 'PreapprovalsView',
    components: {PreapprovalsList},
    beforeMount() {
      this.fetchProjects()
    },
    methods: {
      ...mapActions('profile',['fetchProjects']),
    },
  }
</script>
